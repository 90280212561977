<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="breadcrumb">
      <b-row
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        class="content-header"
      >
        <b-col class="content-header-left mb-2" cols="12" md="9">
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <div class="breadcrumb-wrapper">
                <b-breadcrumb>
                  <b-breadcrumb-item to="/">
                    <feather-icon
                      icon="HomeIcon"
                      size="16"
                      class="align-text-top"
                    />
                  </b-breadcrumb-item>
                  <b-breadcrumb-item
                    v-for="item in $route.meta.breadcrumb"
                    :key="item.text"
                    :active="item.active"
                    :to="item.to"
                  >
                    {{ item.text }}
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div slot="footer">
      <div class="text-center">FinKit</div>
    </div>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
